import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  autoPlacement,
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";

import { usePrevious } from "@sellernote/_shared/src/utils/common/hook";
import FilterIcon from "@sellernote/_sds-v2/src/components/svgIcons/FilterIcon";

import { COLOR } from "../../../styles/colors";

import Button from "../../button/Button";
import InputSearch from "../../form/InputSearch";
import SelectButton from "../../form/SelectButton";
import Styled from "./index.styles";

interface SingleFilterProps<V extends string | number> {
  filterType: "single";
  onFilterSelect: (selectedFilter: V) => void;
  selectedFilter?: V;
}

interface MultiFilterProps<V extends string | number> {
  filterType: "multi";
  onFiltersSelect: (selectedFilters: V[]) => void;
  selectedFilters?: V[];
}

interface FilterOptions<V extends string | number = string> {
  label: string;
  value: V;
  categoryName?: string;
}

interface CommonFilterProps<V extends string | number> {
  filterOptions: FilterOptions<V>[];
  onFilterReset: () => void;
  style?: React.CSSProperties;
  categoryList?: FilterCategoryProps[];
  isFullExplanationTable?: boolean;
  disabled?: boolean;
}

interface FilterCategoryProps {
  /** 카테고리의 노출 타입을 전달합니다. */
  categoryType: "line" | "label";
  /** 필터옵션에서 정렬할 카테고리 이름을 전달합니다. */
  categoryName: string;
}

export type {
  FilterOptions,
  SingleFilterProps,
  MultiFilterProps,
  CommonFilterProps,
  FilterCategoryProps,
};

export default function TableHeaderFilter<V extends string | number>({
  className,
  filterOptions,
  onFilterReset,
  style,
  categoryList,
  isFullExplanationTable,
  disabled,
  ...propsByType
}: {
  className?: string;
} & CommonFilterProps<V> &
  (SingleFilterProps<V> | MultiFilterProps<V>)) {
  const { t } = useTranslation(["sds-v2"]);

  /**
   * multi 선택은 '선택완료'버튼 클릭했을때만 필터적용이 되어야하므로 별도로 local state를 관리함
   */
  const [localSelectedFilters, setLocalSelectedFilters] = useState(
    propsByType.filterType === "multi" ? propsByType.selectedFilters : []
  );

  const previousSelectedFilters = usePrevious(
    propsByType.filterType === "multi" ? propsByType.selectedFilters : undefined
  );

  if (propsByType.filterType === "multi") {
    const isInitialized =
      !propsByType.selectedFilters?.length &&
      previousSelectedFilters?.length !== propsByType.selectedFilters?.length;

    // 외부에서 filter를 초기화된 경우 localSelectedFilters도 초기화함
    if (isInitialized && localSelectedFilters?.length)
      setLocalSelectedFilters([]);
  }

  const isFiltered = useMemo(() => {
    if (propsByType.filterType === "single") {
      return !!propsByType.selectedFilter;
    }

    if (propsByType.filterType === "multi") {
      return !!propsByType.selectedFilters?.length;
    }

    return false;
  }, [propsByType]);

  const [showsFilterSelectPanel, setShowsFilterSelectPanel] = useState(false);

  const handleFilterSelect = useCallback(
    (selectedFilter: V) => {
      if (propsByType.filterType === "single") {
        propsByType.onFilterSelect(selectedFilter);
      }

      if (propsByType.filterType === "multi") {
        setLocalSelectedFilters([
          ...new Set([...(localSelectedFilters || []), selectedFilter]),
        ]);
      }
    },
    [localSelectedFilters, propsByType]
  );

  const handleFilterUnselect = useCallback(
    (selectedFilter: V) => {
      if (propsByType.filterType === "multi") {
        setLocalSelectedFilters([
          ...(localSelectedFilters || []).filter((v) => v !== selectedFilter),
        ]);
      }
    },
    [localSelectedFilters, propsByType.filterType]
  );

  const handleFilterReset = useCallback(() => {
    onFilterReset();

    if (propsByType.filterType === "multi") {
      setLocalSelectedFilters([]);
    }
  }, [onFilterReset, propsByType.filterType]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchResultFilterOptionList = useCallback(
    ({ label }: FilterOptions<V>) =>
      label.toLowerCase().includes(searchTerm.toLowerCase()),
    [searchTerm]
  );

  const searchResultFilterOptions = useMemo(() => {
    const hasCategory = Boolean(categoryList?.length);

    const searchResultOptionList = filterOptions.filter(
      handleSearchResultFilterOptionList
    );

    if (hasCategory) {
      // 카테고리를 설정한 경우 카테고리 라벨링 기준으로 정렬
      const searchResultOptionListBySortedCategory = filterOptions.sort(
        (a, b) => (a.categoryName || "").localeCompare(b.categoryName || "")
      );

      if (!searchTerm) return searchResultOptionListBySortedCategory;

      return searchResultOptionListBySortedCategory.filter(
        handleSearchResultFilterOptionList
      );
    }

    if (!searchTerm) return filterOptions;

    return searchResultOptionList;
  }, [
    filterOptions,
    searchTerm,
    categoryList,
    handleSearchResultFilterOptionList,
  ]);

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy: "fixed",
    open: showsFilterSelectPanel,
    onOpenChange: setShowsFilterSelectPanel,
    middleware: [
      offset(4),
      autoPlacement({ allowedPlacements: ["bottom-start", "bottom-end"] }),
    ],
  });

  const dismiss = useDismiss(context, {
    // 오버플로우 영역을 스크롤하면 옵션리스트가 닫히는 조건 활성화 (모바일 IOS 대응)
    ancestorScroll: true,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const showsSearchInput = filterOptions.length >= 10;

  const handleShowsFilterSelectPanelOpen = () => {
    if (disabled) return;

    if (propsByType.filterType === "multi") {
      setLocalSelectedFilters(propsByType.selectedFilters);
    }
    setShowsFilterSelectPanel(true);
  };

  return (
    <Styled.container
      className={`${className ? className : ""} table-header-filter`}
      isFiltered={isFiltered}
      disabled={disabled}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      {isFiltered && <div className="dot" />}

      <div className="icon">
        <FilterIcon
          width={16}
          height={16}
          color={disabled ? COLOR.grayScale_400 : COLOR.grayScale_700}
          onClick={handleShowsFilterSelectPanelOpen}
        />
      </div>

      {showsFilterSelectPanel && (
        <Styled.filterSelectPanel
          isFullExplanationTable={isFullExplanationTable}
          style={floatingStyles}
          ref={refs.setFloating}
          {...getFloatingProps()}
        >
          {propsByType.filterType === "multi" && showsSearchInput && (
            <InputSearch
              inputValue={searchTerm}
              searchIconPosition="right"
              size="small"
              onInputValueChange={(e) => {
                const { value } = e.target;
                setSearchTerm(value);
              }}
              onReset={() => setSearchTerm("")}
            />
          )}

          <div className="options">
            {propsByType.filterType === "single" &&
              filterOptions.map(({ label, value }) => {
                return (
                  <SelectButton
                    key={value}
                    label={label}
                    onSelect={() => handleFilterSelect(value)}
                    size="default"
                    uiType="radio"
                    value="value"
                    selected={propsByType.selectedFilter === value}
                  />
                );
              })}

            {propsByType.filterType === "multi" && (
              <>
                {searchResultFilterOptions.length ? (
                  searchResultFilterOptions.map(
                    (
                      { label, value, categoryName: filterOptionCategoryName },
                      _,
                      filterOptionsList
                    ) => {
                      const isSelected = localSelectedFilters?.some(
                        (filter) => filter === value
                      );

                      const categoryInfo = categoryList?.find(
                        ({ categoryName }) =>
                          categoryName === filterOptionCategoryName
                      );

                      const hasCategory = Boolean(categoryInfo);

                      const currentCategoryFirstFilterOption =
                        filterOptionsList.find(
                          ({ categoryName }) =>
                            categoryName === filterOptionCategoryName
                        );

                      const isFirstCategoryFilterOption =
                        currentCategoryFirstFilterOption?.value === value &&
                        currentCategoryFirstFilterOption?.label === label;

                      return (
                        <>
                          {hasCategory && isFirstCategoryFilterOption && (
                            <>
                              {categoryInfo?.categoryType === "label" ? (
                                <Styled.categoryLabel className="category-element label">
                                  {
                                    currentCategoryFirstFilterOption.categoryName
                                  }
                                </Styled.categoryLabel>
                              ) : (
                                <Styled.categoryLine className="category-element line" />
                              )}
                            </>
                          )}

                          <SelectButton
                            className={`custom-select-button ${categoryInfo?.categoryType}`}
                            key={value}
                            label={label}
                            onSelect={() =>
                              isSelected
                                ? handleFilterUnselect(value)
                                : handleFilterSelect(value)
                            }
                            size="default"
                            uiType="checkboxFilled"
                            value="value"
                            selected={isSelected}
                          />
                        </>
                      );
                    }
                  )
                ) : (
                  <Styled.emptyMessage>
                    {t("TableHeaderFilter_검색결과없음")}
                  </Styled.emptyMessage>
                )}
              </>
            )}
          </div>

          <div className="actions">
            <Button
              borderType="outlined"
              className="clear"
              handleClick={handleFilterReset}
              label={t("TableHeaderFilter_선택초기화")}
              size="small"
              theme="tertiary"
              width="100%"
            />

            {propsByType.filterType === "multi" && (
              <Button
                borderType="outlined"
                className="submit"
                handleClick={() => {
                  propsByType.onFiltersSelect(localSelectedFilters || []);
                  setShowsFilterSelectPanel(false);
                }}
                label={t("TableHeaderFilter_선택완료")}
                size="small"
                theme="secondary"
                width="100%"
              />
            )}
          </div>
        </Styled.filterSelectPanel>
      )}
    </Styled.container>
  );
}
